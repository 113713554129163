/**
 * @generated SignedSource<<4f216b879b0c797933c254854a9e8803>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlimTalkTemplateWindowPaginator_query$data = {
  readonly alimTalkTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
    readonly pages: {
      readonly " $fragmentSpreads": FragmentRefs<"Pagination_pages">;
    };
    readonly totalCount: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection">;
  };
  readonly " $fragmentType": "AlimTalkTemplateWindowPaginator_query";
};
export type AlimTalkTemplateWindowPaginator_query$key = {
  readonly " $data"?: AlimTalkTemplateWindowPaginator_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlimTalkTemplateWindowPaginator_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order"
    },
    {
      "defaultValue": 1,
      "kind": "LocalArgument",
      "name": "page"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./AlimTalkTemplateWindowPaginatorRefreshQuery.graphql')
    }
  },
  "name": "AlimTalkTemplateWindowPaginator_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        },
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        },
        {
          "kind": "Variable",
          "name": "pageSize",
          "variableName": "pageSize"
        }
      ],
      "concreteType": "AlimTalkTemplateConnection",
      "kind": "LinkedField",
      "name": "alimTalkTemplates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Pages",
          "kind": "LinkedField",
          "name": "pages",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Pagination_pages"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AlimTalkTemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AlimTalkTemplate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "777e12872ef956cfeb74fc115df1f11f";

export default node;
