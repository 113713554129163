/**
 * @generated SignedSource<<ee01e0d9369e79cadc220adea356c87a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlimTalkTaskId_alimTalkPreviewQuery$variables = {
  id: string;
  query: string;
};
export type AlimTalkTaskId_alimTalkPreviewQuery$data = {
  readonly alimTalkPreview: {
    readonly targetCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"AlimTalkPreview_alimTalkPreview">;
  };
  readonly alimTalkTemplate: {
    readonly id: string;
    readonly parameterNames: ReadonlyArray<string>;
    readonly templateCode: string;
    readonly " $fragmentSpreads": FragmentRefs<"AlimTalkPreview_alimTalkTemplate">;
  };
};
export type AlimTalkTaskId_alimTalkPreviewQuery = {
  response: AlimTalkTaskId_alimTalkPreviewQuery$data;
  variables: AlimTalkTaskId_alimTalkPreviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "templateCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parameterNames",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "templateCodeId",
    "variableName": "id"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AlimTalkTaskId_alimTalkPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AlimTalkTemplate",
        "kind": "LinkedField",
        "name": "alimTalkTemplate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AlimTalkPreview_alimTalkTemplate"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "AlimTalkPreview",
        "kind": "LinkedField",
        "name": "alimTalkPreview",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AlimTalkPreview_alimTalkPreview"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AlimTalkTaskId_alimTalkPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AlimTalkTemplate",
        "kind": "LinkedField",
        "name": "alimTalkTemplate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messageType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emphasizeType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adContent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extraContent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "additionalTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "AlimTalkPreview",
        "kind": "LinkedField",
        "name": "alimTalkPreview",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AlimTalkMessage",
            "kind": "LinkedField",
            "name": "messages",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "to",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "headerContent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AlimTalkMessageItemHighlight",
                "kind": "LinkedField",
                "name": "itemHighlight",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AlimTalkMessageItem",
                "kind": "LinkedField",
                "name": "item",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AlimTalkMessageItemListElement",
                    "kind": "LinkedField",
                    "name": "list",
                    "plural": true,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AlimTalkMessageItemSummary",
                    "kind": "LinkedField",
                    "name": "summary",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AlimTalkMessageButton",
                "kind": "LinkedField",
                "name": "buttons",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "linkMobile",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "linkPc",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schemeIos",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schemeAndroid",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4aeee29edb2fa80266e7a5eb283ffc0d",
    "id": null,
    "metadata": {},
    "name": "AlimTalkTaskId_alimTalkPreviewQuery",
    "operationKind": "query",
    "text": "query AlimTalkTaskId_alimTalkPreviewQuery(\n  $id: ID!\n  $query: String!\n) {\n  alimTalkTemplate(id: $id) {\n    id\n    templateCode\n    parameterNames\n    ...AlimTalkPreview_alimTalkTemplate\n  }\n  alimTalkPreview(templateCodeId: $id, query: $query) {\n    targetCount\n    ...AlimTalkPreview_alimTalkPreview\n  }\n}\n\nfragment AlimTalkPreview_alimTalkPreview on AlimTalkPreview {\n  targetCount\n  messages {\n    to\n    title\n    headerContent\n    itemHighlight {\n      title\n      description\n    }\n    item {\n      list {\n        title\n        description\n      }\n      summary {\n        title\n        description\n      }\n    }\n    content\n    buttons {\n      type\n      name\n      linkMobile\n      linkPc\n      schemeIos\n      schemeAndroid\n    }\n  }\n  url\n}\n\nfragment AlimTalkPreview_alimTalkTemplate on AlimTalkTemplate {\n  messageType\n  emphasizeType\n  adContent\n  extraContent\n  additionalTitle\n  imageName\n  imageUrl\n}\n"
  }
};
})();

(node as any).hash = "56f85d3d5d26226f1d6995314e48443e";

export default node;
