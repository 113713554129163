/**
 * @generated SignedSource<<9d2c682ee9654597e3d17948088d4506>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProblemDifficultyLevelEnum = "high" | "highest" | "low" | "lowest" | "medium" | "%future added value";
export type BookBookScrapDeleteDialog_bookScrapQuery$variables = {
  id: string;
};
export type BookBookScrapDeleteDialog_bookScrapQuery$data = {
  readonly bookScrap: {
    readonly difficultyLevel: ProblemDifficultyLevelEnum | null;
    readonly id: string;
    readonly problem: {
      readonly id: string;
      readonly objectUrl: string;
    };
    readonly problemBookPage: number;
    readonly problemNumber: string;
    readonly problemTex: string | null;
    readonly solution: {
      readonly id: string;
      readonly objectUrl: string;
    };
    readonly solutionBookPage: number;
    readonly solutionTex: string | null;
    readonly unitD: {
      readonly id: string;
      readonly title: string;
      readonly unitATitle: string;
      readonly unitBTitle: string;
      readonly unitCTitle: string;
    } | null;
  };
};
export type BookBookScrapDeleteDialog_bookScrapQuery = {
  response: BookBookScrapDeleteDialog_bookScrapQuery$data;
  variables: BookBookScrapDeleteDialog_bookScrapQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "BookScrap",
    "kind": "LinkedField",
    "name": "bookScrap",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "difficultyLevel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UnitD",
        "kind": "LinkedField",
        "name": "unitD",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitATitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitBTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitCTitle",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problemBookPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "solutionBookPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problemNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "problem",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "solution",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problemTex",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "solutionTex",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookBookScrapDeleteDialog_bookScrapQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookBookScrapDeleteDialog_bookScrapQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6427c77f9bb92f9c298cff4d150a8db1",
    "id": null,
    "metadata": {},
    "name": "BookBookScrapDeleteDialog_bookScrapQuery",
    "operationKind": "query",
    "text": "query BookBookScrapDeleteDialog_bookScrapQuery(\n  $id: ID!\n) {\n  bookScrap(id: $id) {\n    id\n    difficultyLevel\n    unitD {\n      id\n      title\n      unitATitle\n      unitBTitle\n      unitCTitle\n    }\n    problemBookPage\n    solutionBookPage\n    problemNumber\n    problem {\n      id\n      objectUrl\n    }\n    solution {\n      id\n      objectUrl\n    }\n    problemTex\n    solutionTex\n  }\n}\n"
  }
};
})();

(node as any).hash = "e57f177edcb026ee10ce23b31b789a01";

export default node;
