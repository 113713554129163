/**
 * @generated SignedSource<<e3263e1b6b87cc05073592a004c1f9c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EmphasizeTypeEnum = "IMAGE" | "ITEM_LIST" | "NONE" | "TEXT" | "%future added value";
export type MessageTypeEnum = "AD" | "BA" | "EX" | "MI" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AlimTalkPreview_alimTalkTemplate$data = {
  readonly adContent: string | null;
  readonly additionalTitle: string | null;
  readonly emphasizeType: EmphasizeTypeEnum;
  readonly extraContent: string | null;
  readonly imageName: string | null;
  readonly imageUrl: string | null;
  readonly messageType: MessageTypeEnum;
  readonly " $fragmentType": "AlimTalkPreview_alimTalkTemplate";
};
export type AlimTalkPreview_alimTalkTemplate$key = {
  readonly " $data"?: AlimTalkPreview_alimTalkTemplate$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlimTalkPreview_alimTalkTemplate">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlimTalkPreview_alimTalkTemplate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emphasizeType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    }
  ],
  "type": "AlimTalkTemplate",
  "abstractKey": null
};

(node as any).hash = "fc9242d141ae2987552b6a34c44609e7";

export default node;
