import { useRouter } from 'next/router';
import React from 'react';
import { graphql } from 'react-relay';

import AlimTalkPreview from '../../components/alimTalk/AlimTalkPreview';
import AlimTalkTaskCreateDialog from '../../components/alimTalk/AlimTalkTaskCreateDialog';
import AlimTalkTemplateDescriptionList from '../../components/alimTalk/AlimTalkTemplateDescriptionList';
import Card from '../../components/core/Card';
import DialogButton from '../../components/core/DialogButton';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { AlimTalkTemplateId_alimTalkTemplateQuery } from '../../relay/__generated__/AlimTalkTemplateId_alimTalkTemplateQuery.graphql';
import { NextPage } from '../_app';

const alimTalkTemplateQuery = graphql`
  query AlimTalkTemplateId_alimTalkTemplateQuery($id: ID!, $query: String!) {
    alimTalkTemplate(id: $id) {
      id
      templateName
      parameterNames
      ...AlimTalkTemplateDescriptionList_alimTalkTemplate
      ...AlimTalkPreview_alimTalkTemplate
    }
    alimTalkPreview(templateCodeId: $id, query: $query) {
      ...AlimTalkPreview_alimTalkPreview
    }
  }
`;

type Props = {};

const AlimTalkTemplateId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const alimTalkTemplateId = router.query.alimTalkTemplateId as string;

  const [{ alimTalkTemplate, alimTalkPreview }] = useLazyLoadQuery<AlimTalkTemplateId_alimTalkTemplateQuery>(
    alimTalkTemplateQuery,
    {
      id: alimTalkTemplateId,
      query: '',
    },
  );

  if (!alimTalkTemplate) return null;

  const { templateName } = alimTalkTemplate;

  return (
    <View>
      <Head siteTitle={`알림톡 템플릿 | ${templateName}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{templateName}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              <Stack.Item>
                <DialogButton
                  size={'large'}
                  renderDialog={({ isOpen, closeDialog }) => (
                    <AlimTalkTaskCreateDialog
                      templateCodeId={alimTalkTemplateId}
                      parameterNames={alimTalkTemplate.parameterNames}
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      full
                      config={{
                        onCompleted: ({ alimTalkTaskCreate }) => {
                          toast('푸시 알림이 생성됐어요!', 'success');
                          closeDialog();
                          router.push(`/alimTalkTask/${alimTalkTaskCreate.id}`);
                        },
                        onError: () => {
                          toast('다시 생성해 주세요', 'error');
                        },
                      }}
                    />
                  )}
                  variant={'primary'}
                >
                  생성하기
                </DialogButton>
              </Stack.Item>
            </Stack>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid gapX={5} gapY={3} reverse={[true, true, false]}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <View sx={{ marginTop: 5 }}>
              <Text fontSize={1} fontWeight={'bold'}>
                알림톡 템플릿 정보
              </Text>
              <Card sx={{ padding: 4, marginTop: 2 }}>
                <AlimTalkTemplateDescriptionList
                  alimTalkTemplate={alimTalkTemplate}
                  titleUnitSize={[1, 1, 1 / 6]}
                  descriptionUnitSize={[1, 1, 5 / 6]}
                />
              </Card>
            </View>
          </Grid.Unit>
        </Grid>

        <Grid gapX={5} gapY={3} reverse={[true, true, false]}>
          <Grid.Unit size={[1, 1, 1]}>
            <View sx={{ marginTop: 5 }}>
              <Text fontSize={1} fontWeight={'bold'}>
                알림톡 템플릿 미리보기
              </Text>
            </View>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1]}>
            <View>
              <AlimTalkPreview alimTalkPreview={alimTalkPreview} alimTalkTemplate={alimTalkTemplate} />
            </View>
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

AlimTalkTemplateId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
AlimTalkTemplateId.authenticated = true;
AlimTalkTemplateId.routes = [
  {
    id: 'alimTalkTemplateId',
    pathname: '/alimTalkTemplate/[alimTalkTemplateId]',
    name: '알림톡 템플릿 상세',
    permissions: ['app_push_read'],
  },
];

export default AlimTalkTemplateId;
