/**
 * @generated SignedSource<<7b38d740c85104c37e0c4d7dc0d9d0cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlimTalkTaskId_alimTalkTaskQuery$variables = {
  id: string;
};
export type AlimTalkTaskId_alimTalkTaskQuery$data = {
  readonly alimTalkTask: {
    readonly description: string | null;
    readonly id: string;
    readonly isActive: boolean;
    readonly period: number | null;
    readonly query: string;
    readonly startAt: any;
    readonly template: {
      readonly id: string;
      readonly parameterNames: ReadonlyArray<string>;
      readonly templateCode: string;
    };
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"AlimTalkTaskDescriptionList_alimTalkTask">;
  };
};
export type AlimTalkTaskId_alimTalkTaskQuery = {
  response: AlimTalkTaskId_alimTalkTaskQuery$data;
  variables: AlimTalkTaskId_alimTalkTaskQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "query",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "period",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "AlimTalkTemplate",
  "kind": "LinkedField",
  "name": "template",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parameterNames",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateCode",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AlimTalkTaskId_alimTalkTaskQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AlimTalkTask",
        "kind": "LinkedField",
        "name": "alimTalkTask",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AlimTalkTaskDescriptionList_alimTalkTask"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AlimTalkTaskId_alimTalkTaskQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AlimTalkTask",
        "kind": "LinkedField",
        "name": "alimTalkTask",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modified",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ce6519c0833d8bbf29d48865ea63f76",
    "id": null,
    "metadata": {},
    "name": "AlimTalkTaskId_alimTalkTaskQuery",
    "operationKind": "query",
    "text": "query AlimTalkTaskId_alimTalkTaskQuery(\n  $id: ID!\n) {\n  alimTalkTask(id: $id) {\n    id\n    title\n    description\n    query\n    startAt\n    period\n    isActive\n    template {\n      id\n      parameterNames\n      templateCode\n    }\n    ...AlimTalkTaskDescriptionList_alimTalkTask\n  }\n}\n\nfragment AlimTalkTaskDescriptionList_alimTalkTask on AlimTalkTask {\n  id\n  created\n  modified\n  title\n  description\n  startAt\n  period\n  isActive\n}\n"
  }
};
})();

(node as any).hash = "43e889737caed2173d9324fd958a6582";

export default node;
