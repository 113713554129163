import { graphql, useFragment } from 'react-relay';

import {
  AlimTalkTemplateDescriptionList_alimTalkTemplate$data,
  AlimTalkTemplateDescriptionList_alimTalkTemplate$key,
} from '../../../relay/__generated__/AlimTalkTemplateDescriptionList_alimTalkTemplate.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import Label from '../../core/Label';
import Text from '../../core/Text';
import View from '../../core/View';

const alimTalkTemplateFragment = graphql`
  fragment AlimTalkTemplateDescriptionList_alimTalkTemplate on AlimTalkTemplate {
    parameterNames
    createTime
    updateTime
    templateCode
    messageType
    emphasizeType
  }
`;

type Props = {
  alimTalkTemplate: AlimTalkTemplateDescriptionList_alimTalkTemplate$key;
  type?: 'default' | 'activity';
} & Omit<
  DescriptionListProps<AlimTalkTemplateDescriptionList_alimTalkTemplate$data>,
  'item' | 'itemDescriptions' | 'picks'
>;

const AlimTalkTemplateDescriptionList = ({ alimTalkTemplate: alimTalkTemplateReference, ...props }: Props) => {
  const alimTalkTemplate = useFragment(alimTalkTemplateFragment, alimTalkTemplateReference);

  return (
    <DescriptionList
      item={alimTalkTemplate}
      itemDescriptions={{
        createTime: {
          title: '생성일시',
          renderValue: ({ createTime }) => <DateText fontSize={1}>{createTime}</DateText>,
        },
        updateTime: {
          title: '수정일시',
          renderValue: ({ updateTime }) => <DateText fontSize={1}>{updateTime}</DateText>,
        },
        templateCode: {
          title: '템플릿 코드',
          renderValue: ({ templateCode }) => <Text fontSize={1}>{templateCode}</Text>,
        },
        parameterNames: {
          title: '변수명',
          renderValue: ({ parameterNames }) => (
            <View sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              {parameterNames.map((name) => (
                <Label key={name} variant="default">
                  {name}
                </Label>
              ))}
            </View>
          ),
        },
        messageType: {
          title: '메시지 유형',
          renderValue: ({ messageType }) => <EnumPair typename={'MessageTypeEnum'}>{messageType}</EnumPair>,
        },
        emphasizeType: {
          title: '강조 유형',
          renderValue: ({ emphasizeType }) => <EnumPair typename={'EmphasizeTypeEnum'}>{emphasizeType}</EnumPair>,
        },
      }}
      picks={['createTime', 'updateTime', 'templateCode', 'parameterNames', 'messageType', 'emphasizeType']}
      {...props}
    />
  );
};

export default AlimTalkTemplateDescriptionList;
