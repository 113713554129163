import React, { Suspense } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import { AlimTalkTaskCreateDialog_alimTalkPreviewQuery } from '../../../relay/__generated__/AlimTalkTaskCreateDialog_alimTalkPreviewQuery.graphql';
import { AlimTalkTaskCreateDialog_alimTalkTaskCreateMutation } from '../../../relay/__generated__/AlimTalkTaskCreateDialog_alimTalkTaskCreateMutation.graphql';
import { formatISO } from '../../../utils/date';
import { isNullable } from '../../../utils/is';
import { numberWithCommas } from '../../../utils/number';
import Button from '../../core/Button';
import DatetimeField from '../../core/DatetimeField';
import Dialog, { DialogProps } from '../../core/Dialog';
import DialogButton from '../../core/DialogButton';
import ErrorBoundary from '../../core/ErrorBoundary';
import ErrorState from '../../core/ErrorState';
import FormLayout from '../../core/FormLayout';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import SQLEditorField from '../../core/SQLEditorField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';
import View from '../../core/View';
import AlimTalkPreview from '../AlimTalkPreview';

type Props = { templateCodeId: string; parameterNames: readonly string[] } & DialogProps &
  Partial<
    Pick<
      MutationFormikProps<AlimTalkTaskCreateDialog_alimTalkTaskCreateMutation>,
      'config' | 'onSubmit' | 'connections' | 'initialValues'
    >
  >;

const alimTalkPreviewForAlimTalkTaskCreateDialog = graphql`
  query AlimTalkTaskCreateDialog_alimTalkPreviewQuery($id: ID!, $query: String!) {
    alimTalkTemplate(id: $id) {
      id
      templateName
      parameterNames
      ...AlimTalkTemplateDescriptionList_alimTalkTemplate
      ...AlimTalkPreview_alimTalkTemplate
    }
    alimTalkPreview(templateCodeId: $id, query: $query) {
      targetCount
      ...AlimTalkPreview_alimTalkPreview
    }
  }
`;

const AlimTalkCreateDialog = ({
  config,
  onSubmit,
  connections,
  initialValues,
  templateCodeId,
  parameterNames,
  ...props
}: Props) => {
  const defaultValues: MutationFormikProps<AlimTalkTaskCreateDialog_alimTalkTaskCreateMutation>['initialValues'] = {
    title: '',
    description: null,
    startAt: formatISO(Date.now()),
    templateCodeId: templateCodeId,
    period: 7,
    query: '',
    csvFile: null,
  };

  const [previewQueryReference, loadPreviewQuery] = useQueryLoader<AlimTalkTaskCreateDialog_alimTalkPreviewQuery>(
    alimTalkPreviewForAlimTalkTaskCreateDialog,
  );

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>알림톡 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<AlimTalkTaskCreateDialog_alimTalkTaskCreateMutation>
        mutation={graphql`
          mutation AlimTalkTaskCreateDialog_alimTalkTaskCreateMutation($input: AlimTalkTaskCreateInput!) {
            alimTalkTaskCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{ ...defaultValues, ...initialValues }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
        enableReinitialize={false}
      >
        {({ values, submitForm }, { scrollContainerRef, nonFieldErrorRef }) => {
          return (
            <>
              <Dialog.Body ref={scrollContainerRef}>
                <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
                <FormLayout>
                  <TextField label={'제목'} required placeholder={'제목 입력'} name={'title'} autoComplete={'off'} />
                  <TextareaField label={'설명'} placeholder={'설명 입력'} name={'description'} autoComplete={'off'} />
                  <View>
                    <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>푸시 알림</Text>
                  </View>
                  <DatetimeField
                    label={'시작일'}
                    required
                    name={'startAt'}
                    datePickerProps={{ placeholder: '시작일 선택' }}
                  />
                  <NumberField label={'주기'} required name={'period'} />
                  <View sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>변수명</Text>
                    {parameterNames.map((name) => (
                      <Label key={name} variant="default">
                        {name}
                      </Label>
                    ))}
                  </View>
                  <SQLEditorField label={'SQL'} name={'query'} height={200} required />
                </FormLayout>
              </Dialog.Body>
              <Dialog.Footer>
                <DialogButton
                  variant={'primary'}
                  size={'large'}
                  renderDialog={({ isOpen, closeDialog }) => (
                    <Dialog isOpen={isOpen} onDismiss={closeDialog} wide>
                      <Dialog.Header>
                        <Text fontSize={3} fontWeight={'bold'}>
                          생성하기
                        </Text>
                      </Dialog.Header>
                      {previewQueryReference ? (
                        <Suspense
                          fallback={
                            <View sx={{ padding: 5 }}>
                              <Spinner />
                            </View>
                          }
                        >
                          <ErrorBoundary
                            key={previewQueryReference.fetchKey}
                            fallback={({ parsedGraphQLError }) => {
                              return (
                                <View sx={{ padding: 5 }}>
                                  <ErrorState
                                    description={
                                      parsedGraphQLError?.[0].message || '작성된 SQL을 한 번 더 확인해주세요'
                                    }
                                  />
                                </View>
                              );
                            }}
                          >
                            <PreloadedQueryRenderer
                              query={alimTalkPreviewForAlimTalkTaskCreateDialog}
                              queryReference={previewQueryReference}
                            >
                              {({ alimTalkPreview, alimTalkTemplate }) => {
                                if (!alimTalkPreview || !alimTalkTemplate) {
                                  return (
                                    <View sx={{ padding: 5 }}>
                                      <ErrorState description="쿼리가 올바르지 않거나 쿼리 결과가 없어요." />
                                    </View>
                                  );
                                }
                                return (
                                  <>
                                    <Dialog.Body>
                                      <Text fontSize={1} fontWeight={'bold'}>
                                        {`약 ${
                                          !isNullable(alimTalkPreview.targetCount)
                                            ? numberWithCommas(alimTalkPreview.targetCount)
                                            : '-'
                                        }명 발송 예정`}
                                      </Text>
                                      <View sx={{ marginTop: 5 }}>
                                        <Text fontSize={1} fontWeight={'bold'}>
                                          미리보기
                                        </Text>
                                        <AlimTalkPreview
                                          alimTalkPreview={alimTalkPreview}
                                          alimTalkTemplate={alimTalkTemplate}
                                        />
                                      </View>
                                    </Dialog.Body>
                                    <Dialog.Footer>
                                      <Button
                                        variant={'primary'}
                                        size={'large'}
                                        onClick={() => {
                                          submitForm();
                                          closeDialog();
                                        }}
                                      >
                                        생성하기
                                      </Button>
                                    </Dialog.Footer>
                                  </>
                                );
                              }}
                            </PreloadedQueryRenderer>
                          </ErrorBoundary>
                        </Suspense>
                      ) : null}
                    </Dialog>
                  )}
                  onClick={() => {
                    const { templateCodeId, query } = values;
                    loadPreviewQuery({
                      id: templateCodeId,
                      query: query,
                    });
                  }}
                  disabled={!values.query.trim()}
                >
                  생성하기
                </DialogButton>
              </Dialog.Footer>
            </>
          );
        }}
      </MutationFormik>
    </Dialog>
  );
};

export default AlimTalkCreateDialog;
