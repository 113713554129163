import { graphql, useFragment } from 'react-relay';

import {
  AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection$data,
  AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection$key,
} from '../../../relay/__generated__/AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection.graphql';
import { numberWithCommas } from '../../../utils/number';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';

export const AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection = graphql`
  fragment AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection on AlimTalkExecutionConnection {
    edges {
      node {
        id
        sequence
        created
        executedAt
        queriedAt
        status
        targetCount
        task {
          id
        }
      }
    }
  }
`;

type AlimTalkExecutionNode =
  AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection$data['edges'][number]['node'];

type Props = {
  alimTalkExecutionConnection: AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection$key;
} & Omit<DataTableProps<AlimTalkExecutionNode>, 'rows' | 'columns'>;

const AlimTalkExecutionConnectionDataTable = ({ alimTalkExecutionConnection, ...props }: Props) => {
  const { edges } = useFragment(
    AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection,
    alimTalkExecutionConnection,
  );

  const rows = edges.map(({ node }) => node).filter((node): node is AlimTalkExecutionNode => Boolean(node));

  return (
    <DataTable
      rows={rows}
      columns={[
        {
          field: 'created',
          title: '생성일시',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
          width: 150,
        },
        {
          field: 'executedAt',
          title: '예약일시',
          renderValue: ({ executedAt }) => (executedAt ? <DateText>{executedAt}</DateText> : '-'),
          width: 150,
        },
        {
          field: 'status',
          title: '실행상태',
          renderValue: ({ status }) => <EnumPair typename={'AlimTalkStatusEnum'}>{status}</EnumPair>,
          width: 100,
        },
        {
          field: 'targetCount',
          title: '발송횟수',
          renderValue: ({ targetCount }) => <Text>{numberWithCommas(targetCount)}</Text>,
          width: 120,
        },
      ]}
      {...props}
    />
  );
};

export default AlimTalkExecutionConnectionDataTable;
