/**
 * @generated SignedSource<<cc6f491a8101e9d0a3eee56d2788b8c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EmphasizeTypeEnum = "IMAGE" | "ITEM_LIST" | "NONE" | "TEXT" | "%future added value";
export type MessageTypeEnum = "AD" | "BA" | "EX" | "MI" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AlimTalkTemplateDescriptionList_alimTalkTemplate$data = {
  readonly createTime: any;
  readonly emphasizeType: EmphasizeTypeEnum;
  readonly messageType: MessageTypeEnum;
  readonly parameterNames: ReadonlyArray<string>;
  readonly templateCode: string;
  readonly updateTime: any | null;
  readonly " $fragmentType": "AlimTalkTemplateDescriptionList_alimTalkTemplate";
};
export type AlimTalkTemplateDescriptionList_alimTalkTemplate$key = {
  readonly " $data"?: AlimTalkTemplateDescriptionList_alimTalkTemplate$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlimTalkTemplateDescriptionList_alimTalkTemplate">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlimTalkTemplateDescriptionList_alimTalkTemplate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parameterNames",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updateTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emphasizeType",
      "storageKey": null
    }
  ],
  "type": "AlimTalkTemplate",
  "abstractKey": null
};

(node as any).hash = "ae85264a9ac4677bd2d61442a6d18ed3";

export default node;
