/**
 * @generated SignedSource<<2018c54acdc8341c51eb54b135a8fed3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookScrapCreateInput = {
  book: string;
  problem: Upload;
  problemBookPage: number;
  problemNumber: string;
  problemPage: number;
  solution: Upload;
  solutionBookPage: number;
  solutionPage: number;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type BookBookScrapCreateDialog_bookScrapCreateMutation$variables = {
  input: BookScrapCreateInput;
};
export type BookBookScrapCreateDialog_bookScrapCreateMutation$data = {
  readonly bookScrapCreate: {
    readonly book: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"BookActionsMutationButtonStack_book" | "BookBookScrapPaginator_book">;
    };
    readonly id: string;
    readonly problemBookPage: number;
    readonly problemNumber: string;
    readonly problemPage: number;
    readonly solutionBookPage: number;
    readonly solutionPage: number;
  };
};
export type BookBookScrapCreateDialog_bookScrapCreateMutation = {
  response: BookBookScrapCreateDialog_bookScrapCreateMutation$data;
  variables: BookBookScrapCreateDialog_bookScrapCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemPage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "solutionPage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemBookPage",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "solutionBookPage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookBookScrapCreateDialog_bookScrapCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BookScrap",
        "kind": "LinkedField",
        "name": "bookScrapCreate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Book",
            "kind": "LinkedField",
            "name": "book",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BookBookScrapPaginator_book"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BookActionsMutationButtonStack_book"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookBookScrapCreateDialog_bookScrapCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BookScrap",
        "kind": "LinkedField",
        "name": "bookScrapCreate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Book",
            "kind": "LinkedField",
            "name": "book",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BookScrapConnection",
                "kind": "LinkedField",
                "name": "bookScraps",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BookScrapEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BookScrap",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v9/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "difficultyLevel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UnitD",
                            "kind": "LinkedField",
                            "name": "unitD",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "connection",
                "key": "BookBookScrapPaginator_book_bookScraps",
                "kind": "LinkedHandle",
                "name": "bookScraps"
              },
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "solutionPdf",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "problemPdf",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": "latestBookScraps",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  {
                    "kind": "Literal",
                    "name": "order",
                    "value": {
                      "created": "DESC"
                    }
                  }
                ],
                "concreteType": "BookScrapConnection",
                "kind": "LinkedField",
                "name": "bookScraps",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BookScrapEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BookScrap",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "bookScraps(first:1,order:{\"created\":\"DESC\"})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dbb9d00e37ae5410052424e0908f68f6",
    "id": null,
    "metadata": {},
    "name": "BookBookScrapCreateDialog_bookScrapCreateMutation",
    "operationKind": "mutation",
    "text": "mutation BookBookScrapCreateDialog_bookScrapCreateMutation(\n  $input: BookScrapCreateInput!\n) {\n  bookScrapCreate(input: $input) {\n    id\n    problemPage\n    solutionPage\n    problemBookPage\n    solutionBookPage\n    problemNumber\n    book {\n      id\n      ...BookBookScrapPaginator_book\n      ...BookActionsMutationButtonStack_book\n    }\n  }\n}\n\nfragment BookActionsMutationButtonStack_book on Book {\n  id\n  actions\n  ...BookBookScrapCreateDialog_book\n}\n\nfragment BookBookScrapCreateDialog_book on Book {\n  id\n  title\n  solutionPdf {\n    id\n    objectUrl\n  }\n  problemPdf {\n    id\n    objectUrl\n  }\n  latestBookScraps: bookScraps(order: {created: DESC}, first: 1) {\n    totalCount\n    edges {\n      node {\n        id\n        problemPage\n        solutionPage\n        problemBookPage\n        solutionBookPage\n        problemNumber\n      }\n    }\n  }\n}\n\nfragment BookBookScrapPaginator_book on Book {\n  bookScraps {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        actions\n        problemBookPage\n        solutionBookPage\n        problemNumber\n        __typename\n      }\n    }\n    ...BookScrapConnectionDataTable_bookScrapConnection\n  }\n  id\n}\n\nfragment BookScrapConnectionDataTable_bookScrapConnection on BookScrapConnection {\n  edges {\n    node {\n      id\n      actions\n      problemNumber\n      problemBookPage\n      solutionBookPage\n      difficultyLevel\n      unitD {\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ab95acf10a2e1919b8812a6c23b555b";

export default node;
