import { graphql, useFragment } from 'react-relay';

import { AlimTalkPreview_alimTalkPreview$key } from '../../../relay/__generated__/AlimTalkPreview_alimTalkPreview.graphql';
import { AlimTalkPreview_alimTalkTemplate$key } from '../../../relay/__generated__/AlimTalkPreview_alimTalkTemplate.graphql';
import DataTable from '../../core/DataTable';
import EmptyState from '../../core/EmptyState';
import Grid, { GridProps } from '../../core/Grid';
import Text from '../../core/Text';
import Box from '../../core/View';

const alimTalkPreviewFragment = graphql`
  fragment AlimTalkPreview_alimTalkPreview on AlimTalkPreview {
    targetCount
    messages {
      to
      title
      headerContent
      itemHighlight {
        title
        description
      }
      item {
        list {
          title
          description
        }
        summary {
          title
          description
        }
      }
      content
      buttons {
        type
        name
        linkMobile
        linkPc
        schemeIos
        schemeAndroid
      }
    }
    url
  }
`;

const alimTalkPreviewTemplateFragment = graphql`
  fragment AlimTalkPreview_alimTalkTemplate on AlimTalkTemplate {
    messageType
    emphasizeType
    adContent
    extraContent
    additionalTitle
    imageName
    imageUrl
  }
`;

type Props = {
  alimTalkPreview: AlimTalkPreview_alimTalkPreview$key;
  alimTalkTemplate: AlimTalkPreview_alimTalkTemplate$key;
} & GridProps;

const AlimTalkPreview = ({
  alimTalkPreview: alimTalkPreviewReference,
  alimTalkTemplate: alimTalkPreviewTemplateReference,
  ...props
}: Props) => {
  const alimTalkPreview = useFragment(alimTalkPreviewFragment, alimTalkPreviewReference);
  const alimTalkPreviewTemplate = useFragment(alimTalkPreviewTemplateFragment, alimTalkPreviewTemplateReference);
  const { messages } = alimTalkPreview;
  const alimTalkPreviewMessageWithMetadata = messages.map((msg, index) => ({
    ...msg,
    id: index,
    ...alimTalkPreviewTemplate,
  }));

  const { url } = alimTalkPreview;
  return (
    <Grid {...props}>
      <Grid.Unit size={[1, 1, 1]}>
        <iframe src={url} style={{ border: 'none', minWidth: 390, minHeight: 400 }} />
      </Grid.Unit>
      <Grid.Unit size={[1, 1, 1]}>
        <DataTable
          rows={alimTalkPreviewMessageWithMetadata}
          columns={[
            {
              field: 'to',
              title: '수신자',
              renderValue: ({ to }) => (to ? <Text fontSize={1}>{to}</Text> : '-'),
              width: 80,
            },
            {
              field: 'content',
              title: '내용',
              renderValue: ({ content }) => (content ? <Text fontSize={0}>{content}</Text> : '-'),
              width: 200,
            },
            {
              field: 'title',
              title: '제목',
              renderValue: ({ title }) => (title ? <Text fontSize={1}>{title}</Text> : '-'),
              width: 80,
            },
            {
              field: 'additionalTitle',
              title: '추가 제목',
              renderValue: ({ additionalTitle }) =>
                additionalTitle ? <Text fontSize={1}>{additionalTitle}</Text> : '-',
              width: 80,
            },
            {
              field: 'headerContent',
              title: '헤더 내용',
              renderValue: ({ headerContent }) => (headerContent ? <Text fontSize={1}>{headerContent}</Text> : '-'),
              width: 80,
            },
            {
              field: 'buttons',
              title: '버튼',
              renderValue: ({ buttons }) =>
                buttons?.length ? (
                  <Box>
                    {buttons.map((btn, index) => (
                      <Box key={index} mb={3} p={0}>
                        <Box>
                          <Text fontWeight="bold">이름:</Text>
                          <Text>{btn.name}</Text>
                        </Box>
                        <Box mt={2}>
                          <Text fontWeight="bold">링크:</Text>
                          <Text>{btn.linkMobile || btn.linkPc || btn.schemeIos || btn.schemeAndroid}</Text>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  '-'
                ),
              width: 100,
            },
            {
              field: 'item',
              title: '아이템',
              renderValue: ({ item }) =>
                item?.list?.length ? (
                  <Box mb={2}>
                    {item.list.map((listItem, index) => (
                      <Box key={index}>
                        <Text fontWeight={'bold'}>{listItem.title}:</Text>
                        <Text>{listItem.description}</Text>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  '-'
                ),
              width: 100,
            },
            {
              field: 'item',
              title: '아이템 요약',
              renderValue: ({ item }) =>
                item?.summary ? (
                  <Box>
                    <Text fontWeight={'bold'}>제목: </Text>
                    <Text> {item.summary.title}</Text>
                    <Text fontWeight={'bold'}>내용: </Text>
                    <Text> {item.summary.description}</Text>
                  </Box>
                ) : (
                  '-'
                ),
              width: 100,
            },
            {
              field: 'itemHighlight',
              title: '아이템 하이라이트',
              renderValue: ({ itemHighlight }) =>
                itemHighlight ? (
                  <Box>
                    <Text fontWeight={'bold'}>제목: </Text>
                    <Text>{itemHighlight.title}</Text>
                    <Text fontWeight={'bold'}>내용: </Text>
                    <Text>{itemHighlight.description}</Text>
                  </Box>
                ) : (
                  '-'
                ),
              width: 100,
            },
          ]}
          emptyState={<EmptyState title={'입력된 알림톡이 없어요'} />}
        />
      </Grid.Unit>
    </Grid>
  );
};

export default AlimTalkPreview;
