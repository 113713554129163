import { graphql, useFragment } from 'react-relay';

import {
  AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection$data,
  AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection$key,
} from '../../../relay/__generated__/AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';

const AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection = graphql`
  fragment AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection on AlimTalkTemplateConnection {
    edges {
      node {
        id
        templateCode
        templateName
        messageType
        emphasizeType
        templateStatus
        templateInspectionStatus
        createTime
      }
    }
  }
`;

type Props = {
  alimTalkTemplateConnection: AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection$key;
} & Omit<
  DataTableProps<AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const AlimTalkTemplateConnectionDataTable = ({ alimTalkTemplateConnection, ...props }: Props) => {
  const { edges } = useFragment(
    AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection,
    alimTalkTemplateConnection,
  );

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'createTime',
          title: '생성일',
          renderValue: ({ createTime }) => <DateText>{createTime}</DateText>,
          width: 120,
        },
        {
          field: 'templateCode',
          title: '템플릿 코드',
          renderValue: ({ templateCode }) => <Text>{templateCode}</Text>,
          width: 100,
        },
        {
          field: 'templateName',
          title: '템플릿 이름',
          renderValue: ({ templateName }) => <Text>{templateName}</Text>,
          width: 200,
        },
        {
          field: 'messageType',
          title: '메시지 유형',
          renderValue: ({ messageType }) => <EnumPair typename={'MessageTypeEnum'}>{messageType}</EnumPair>,
          width: 80,
        },
        {
          field: 'emphasizeType',
          title: '템플릿 강조 유형',
          renderValue: ({ emphasizeType }) => <EnumPair typename={'EmphasizeTypeEnum'}>{emphasizeType}</EnumPair>,
          width: 80,
        },
        {
          field: 'templateStatus',
          title: '템플릿 상태',
          renderValue: ({ templateStatus }) => <EnumPair typename={'TemplateStatusEnum'}>{templateStatus}</EnumPair>,
          width: 60,
        },
        {
          field: 'templateInspectionStatus',
          title: '템플릿 검수 상태',
          renderValue: ({ templateInspectionStatus }) => (
            <EnumPair typename={'TemplateInspectionStatusEnum'}>{templateInspectionStatus}</EnumPair>
          ),
          width: 60,
        },
      ]}
      {...props}
    />
  );
};

export default AlimTalkTemplateConnectionDataTable;
