import { CommentDiscussionIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import AlimTalkTaskConnectionDataTable from '../../components/alimTalk/AlimTalkTaskConnectionDataTable';
import AlimTalkTaskWindowPaginator from '../../components/alimTalk/AlimTalkTaskWindowPaginator';
import Button from '../../components/core/Button';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import { alimTalkTask_alimTalkTasksQuery } from '../../relay/__generated__/alimTalkTask_alimTalkTasksQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import AlimTalkTaskId from './[alimTalkTaskId]';

const alimTalkTasksForAlimTalkTask = graphql`
  query alimTalkTask_alimTalkTasksQuery(
    $filters: AlimTalkTaskFilter
    $order: AlimTalkTaskOrder
    $page: Int
    $pageSize: Int
  ) {
    ...AlimTalkTaskWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const AlimTalkTask: NextPage<Props> = () => {
  const router = useRouter();
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    order: { type: 'string' },
    page: { type: 'number' },
  });
  const { pageSize } = usePaginationContext();

  const { search, order, page } = initialValues;

  return (
    <View>
      <Head siteTitle={'알림톡'} />
      <ErrorBoundary>
        <QueryFormik<alimTalkTask_alimTalkTasksQuery>
          query={alimTalkTasksForAlimTalkTask}
          staticVariables={{ pageSize }}
          initialValues={{
            filters: {
              search,
            },
            order: order,
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
        >
          {({ values: { filters, order }, resetForm, setFieldValue, submitForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>알림톡</Text>
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'AlimTalkTaskFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<alimTalkTask_alimTalkTasksQuery>>
                        {(queryReference) => (
                          <AlimTalkTaskWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ alimTalkTasks }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(alimTalkTasks?.totalCount || 0)}건
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <AlimTalkTaskConnectionDataTable
                                    alimTalkTaskConnection={alimTalkTasks}
                                    onRowClick={({ id }) => router.push({ pathname: `/alimTalkTask/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </AlimTalkTaskWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

AlimTalkTask.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
AlimTalkTask.authenticated = true;
AlimTalkTask.routes = [
  {
    id: 'alimTalkTask',
    icon: CommentDiscussionIcon,
    pathname: '/alimTalkTask',
    name: '알림톡',
    permissions: ['app_push_read'],
  },
  ...AlimTalkTaskId.routes,
];

export default AlimTalkTask;
