/**
 * @generated SignedSource<<c9ff03a80b8b5c850a8bfe57ad637152>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookScrapsDeleteInput = {
  book: string;
  bookScraps: ReadonlyArray<string>;
};
export type BookId_bookScrapsDeleteMutation$variables = {
  input: BookScrapsDeleteInput;
};
export type BookId_bookScrapsDeleteMutation$data = {
  readonly bookScrapsDelete: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BookBookScrapPaginator_book">;
  };
};
export type BookId_bookScrapsDeleteMutation = {
  response: BookId_bookScrapsDeleteMutation$data;
  variables: BookId_bookScrapsDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookId_bookScrapsDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Book",
        "kind": "LinkedField",
        "name": "bookScrapsDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BookBookScrapPaginator_book"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookId_bookScrapsDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Book",
        "kind": "LinkedField",
        "name": "bookScrapsDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BookScrapConnection",
            "kind": "LinkedField",
            "name": "bookScraps",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BookScrapEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BookScrap",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actions",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "problemBookPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "solutionBookPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "problemNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "difficultyLevel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnitD",
                        "kind": "LinkedField",
                        "name": "unitD",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "connection",
            "key": "BookBookScrapPaginator_book_bookScraps",
            "kind": "LinkedHandle",
            "name": "bookScraps"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc7cec596318edd722cbfaf9d16b3d51",
    "id": null,
    "metadata": {},
    "name": "BookId_bookScrapsDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation BookId_bookScrapsDeleteMutation(\n  $input: BookScrapsDeleteInput!\n) {\n  bookScrapsDelete(input: $input) {\n    id\n    ...BookBookScrapPaginator_book\n  }\n}\n\nfragment BookBookScrapPaginator_book on Book {\n  bookScraps {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        actions\n        problemBookPage\n        solutionBookPage\n        problemNumber\n        __typename\n      }\n    }\n    ...BookScrapConnectionDataTable_bookScrapConnection\n  }\n  id\n}\n\nfragment BookScrapConnectionDataTable_bookScrapConnection on BookScrapConnection {\n  edges {\n    node {\n      id\n      actions\n      problemNumber\n      problemBookPage\n      solutionBookPage\n      difficultyLevel\n      unitD {\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ae8a2c86eb084e189ef87530d2cc4b4";

export default node;
