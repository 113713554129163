/**
 * @generated SignedSource<<aa894a479876e5a2f00e4cd7ef2a945d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AlimTalkTaskDeactivateInput = {
  id: string;
};
export type AlimTalkTaskId_alimTalkTaskDeactivateMutation$variables = {
  input: AlimTalkTaskDeactivateInput;
};
export type AlimTalkTaskId_alimTalkTaskDeactivateMutation$data = {
  readonly alimTalkTaskDeactivate: {
    readonly id: string;
  };
};
export type AlimTalkTaskId_alimTalkTaskDeactivateMutation = {
  response: AlimTalkTaskId_alimTalkTaskDeactivateMutation$data;
  variables: AlimTalkTaskId_alimTalkTaskDeactivateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AlimTalkTask",
    "kind": "LinkedField",
    "name": "alimTalkTaskDeactivate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AlimTalkTaskId_alimTalkTaskDeactivateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AlimTalkTaskId_alimTalkTaskDeactivateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "91104e50a67622ececee5d9a56368df2",
    "id": null,
    "metadata": {},
    "name": "AlimTalkTaskId_alimTalkTaskDeactivateMutation",
    "operationKind": "mutation",
    "text": "mutation AlimTalkTaskId_alimTalkTaskDeactivateMutation(\n  $input: AlimTalkTaskDeactivateInput!\n) {\n  alimTalkTaskDeactivate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2675630e86d515f92c8501ed935e7382";

export default node;
