import { graphql, useFragment } from 'react-relay';

import {
  AlimTalkTaskDescriptionList_alimTalkTask$data,
  AlimTalkTaskDescriptionList_alimTalkTask$key,
} from '../../../relay/__generated__/AlimTalkTaskDescriptionList_alimTalkTask.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import Text from '../../core/Text';

const AlimTalkTaskDescriptionList_alimTalkTask = graphql`
  fragment AlimTalkTaskDescriptionList_alimTalkTask on AlimTalkTask {
    id
    created
    modified
    title
    description
    startAt
    period
    isActive
  }
`;

type Props = {
  alimTalkTask: AlimTalkTaskDescriptionList_alimTalkTask$key;
} & Omit<DescriptionListProps<AlimTalkTaskDescriptionList_alimTalkTask$data>, 'item' | 'itemDescriptions' | 'picks'>;

const AlimTalkTaskDescriptionList = ({ alimTalkTask: alimTalkTaskRef, ...props }: Props) => {
  const alimTalkTask = useFragment(AlimTalkTaskDescriptionList_alimTalkTask, alimTalkTaskRef);

  return (
    <DescriptionList
      item={alimTalkTask}
      itemDescriptions={{
        created: {
          title: '생성일',
          renderValue: ({ created }) => <DateText fontSize={1}>{created}</DateText>,
        },
        modified: {
          title: '수정일',
          renderValue: ({ modified }) => <DateText fontSize={1}>{modified}</DateText>,
        },
        startAt: {
          title: '시작일',
          renderValue: ({ startAt }) => <DateText fontSize={1}>{startAt}</DateText>,
        },
        period: {
          title: '주기',
          renderValue: ({ period }) => <Text fontSize={1}>{period ? period + '일' : '-'}</Text>,
        },
        title: {
          title: '제목',
          renderValue: ({ title }) => <Text fontSize={1}>{title}</Text>,
        },
        description: {
          title: '설명',
          renderValue: ({ description }) => <Text fontSize={1}>{description}</Text>,
        },
        isActive: {
          title: '활성화 여부',
          renderValue: ({ isActive }) => <Text fontSize={1}>{isActive ? 'O' : 'X'}</Text>,
        },
      }}
      picks={['created', 'modified', 'startAt', 'period', 'title', 'description', 'isActive']}
      {...props}
    />
  );
};

export default AlimTalkTaskDescriptionList;
