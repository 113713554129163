/**
 * @generated SignedSource<<32f6694babe6191d7bdaa81683805349>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlimTalkTaskDescriptionList_alimTalkTask$data = {
  readonly created: any;
  readonly description: string | null;
  readonly id: string;
  readonly isActive: boolean;
  readonly modified: any;
  readonly period: number | null;
  readonly startAt: any;
  readonly title: string;
  readonly " $fragmentType": "AlimTalkTaskDescriptionList_alimTalkTask";
};
export type AlimTalkTaskDescriptionList_alimTalkTask$key = {
  readonly " $data"?: AlimTalkTaskDescriptionList_alimTalkTask$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlimTalkTaskDescriptionList_alimTalkTask">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlimTalkTaskDescriptionList_alimTalkTask",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "modified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "period",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    }
  ],
  "type": "AlimTalkTask",
  "abstractKey": null
};

(node as any).hash = "b45117523e91ec96f18fec7353e7be41";

export default node;
