/**
 * @generated SignedSource<<63175bb8b1cb46d6b0382868f1458645>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlimTalkTaskConnectionDataTable_alimTalkTaskConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly createdBy: {
        readonly id: string;
      };
      readonly description: string | null;
      readonly id: string;
      readonly isActive: boolean;
      readonly modified: any;
      readonly modifiedBy: {
        readonly id: string;
      };
      readonly period: number | null;
      readonly query: string;
      readonly sequence: number;
      readonly startAt: any;
      readonly template: {
        readonly templateCode: string;
      };
      readonly title: string;
    };
  }>;
  readonly " $fragmentType": "AlimTalkTaskConnectionDataTable_alimTalkTaskConnection";
};
export type AlimTalkTaskConnectionDataTable_alimTalkTaskConnection$key = {
  readonly " $data"?: AlimTalkTaskConnectionDataTable_alimTalkTaskConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlimTalkTaskConnectionDataTable_alimTalkTaskConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlimTalkTaskConnectionDataTable_alimTalkTaskConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AlimTalkTaskEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AlimTalkTask",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modified",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "modifiedBy",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AlimTalkTemplate",
              "kind": "LinkedField",
              "name": "template",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "templateCode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "query",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "period",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isActive",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AlimTalkTaskConnection",
  "abstractKey": null
};
})();

(node as any).hash = "2be3a29399baf26b0ecaf2b64e79480c";

export default node;
