/**
 * @generated SignedSource<<bec88899d7b0d045303efed4eec9f728>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AlimTalkStatusEnum = "completed" | "failed" | "in_progress" | "scheduled" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly executedAt: any | null;
      readonly id: string;
      readonly queriedAt: any | null;
      readonly sequence: number;
      readonly status: AlimTalkStatusEnum;
      readonly targetCount: number;
      readonly task: {
        readonly id: string;
      };
    };
  }>;
  readonly " $fragmentType": "AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection";
};
export type AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection$key = {
  readonly " $data"?: AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AlimTalkExecutionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AlimTalkExecution",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "executedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "queriedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "targetCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AlimTalkTask",
              "kind": "LinkedField",
              "name": "task",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AlimTalkExecutionConnection",
  "abstractKey": null
};
})();

(node as any).hash = "b9a422c04a1c901373f5707075f5c70f";

export default node;
