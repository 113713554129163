/**
 * @generated SignedSource<<ad6d57b5216856362f022a0115a61890>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlimTalkPreview_alimTalkPreview$data = {
  readonly messages: ReadonlyArray<{
    readonly buttons: ReadonlyArray<{
      readonly linkMobile: string | null;
      readonly linkPc: string | null;
      readonly name: string;
      readonly schemeAndroid: string | null;
      readonly schemeIos: string | null;
      readonly type: string;
    }> | null;
    readonly content: string;
    readonly headerContent: string | null;
    readonly item: {
      readonly list: ReadonlyArray<{
        readonly description: string;
        readonly title: string;
      }>;
      readonly summary: {
        readonly description: string;
        readonly title: string;
      } | null;
    } | null;
    readonly itemHighlight: {
      readonly description: string;
      readonly title: string;
    } | null;
    readonly title: string | null;
    readonly to: string;
  }>;
  readonly targetCount: number;
  readonly url: string;
  readonly " $fragmentType": "AlimTalkPreview_alimTalkPreview";
};
export type AlimTalkPreview_alimTalkPreview$key = {
  readonly " $data"?: AlimTalkPreview_alimTalkPreview$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlimTalkPreview_alimTalkPreview">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlimTalkPreview_alimTalkPreview",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AlimTalkMessage",
      "kind": "LinkedField",
      "name": "messages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "to",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headerContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AlimTalkMessageItemHighlight",
          "kind": "LinkedField",
          "name": "itemHighlight",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AlimTalkMessageItem",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AlimTalkMessageItemListElement",
              "kind": "LinkedField",
              "name": "list",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AlimTalkMessageItemSummary",
              "kind": "LinkedField",
              "name": "summary",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AlimTalkMessageButton",
          "kind": "LinkedField",
          "name": "buttons",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkMobile",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkPc",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "schemeIos",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "schemeAndroid",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "AlimTalkPreview",
  "abstractKey": null
};
})();

(node as any).hash = "55622eb9153c83c59778a10da7014fd6";

export default node;
