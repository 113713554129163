/**
 * @generated SignedSource<<b44000c3a268a6a6d961de2de4cb6c09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlimTalkTaskId_alimTalkExecutionsQuery$variables = {
  id: string;
};
export type AlimTalkTaskId_alimTalkExecutionsQuery$data = {
  readonly alimTalkExecutions: {
    readonly totalCount: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection">;
  };
};
export type AlimTalkTaskId_alimTalkExecutionsQuery = {
  response: AlimTalkTaskId_alimTalkExecutionsQuery$data;
  variables: AlimTalkTaskId_alimTalkExecutionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "taskId_Exact",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "executedAt": "DESC"
    }
  },
  {
    "kind": "Literal",
    "name": "page",
    "value": 0
  },
  {
    "kind": "Literal",
    "name": "pageSize",
    "value": 10
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AlimTalkTaskId_alimTalkExecutionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AlimTalkExecutionConnection",
        "kind": "LinkedField",
        "name": "alimTalkExecutions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AlimTalkTaskId_alimTalkExecutionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AlimTalkExecutionConnection",
        "kind": "LinkedField",
        "name": "alimTalkExecutions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AlimTalkExecutionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AlimTalkExecution",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "executedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "queriedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "targetCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AlimTalkTask",
                    "kind": "LinkedField",
                    "name": "task",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd0eee899d290b7d49e27147d8efbeee",
    "id": null,
    "metadata": {},
    "name": "AlimTalkTaskId_alimTalkExecutionsQuery",
    "operationKind": "query",
    "text": "query AlimTalkTaskId_alimTalkExecutionsQuery(\n  $id: ID!\n) {\n  alimTalkExecutions(filters: {taskId_Exact: $id}, order: {executedAt: DESC}, page: 0, pageSize: 10) {\n    totalCount\n    ...AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection\n  }\n}\n\nfragment AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection on AlimTalkExecutionConnection {\n  edges {\n    node {\n      id\n      sequence\n      created\n      executedAt\n      queriedAt\n      status\n      targetCount\n      task {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dee332ddeeed051485ff85c31d3e31a6";

export default node;
