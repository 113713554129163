/**
 * @generated SignedSource<<5bc0ef53bb80b9c5652f27e0f17ef018>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UnitSchoolGradeEnum = "five" | "four" | "high" | "highest" | "low" | "lowest" | "medium" | "one" | "seven" | "six" | "three" | "two" | "zero" | "%future added value";
export type UnitSchoolYearEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1_2015" | "high_school_1_2022" | "high_school_2_2015" | "high_school_2_daesu_2022" | "high_school_2_mijukbun_2022" | "high_school_3_hwaktong_2022" | "high_school_3_liberal_arts_2015" | "high_school_3_mijukbun2_giha_2022" | "high_school_3_science_2015" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
export type TaskFinalLabelingDialog_unitDefaultRatingQuery$variables = {
  schoolGrade: UnitSchoolGradeEnum;
  schoolYear: UnitSchoolYearEnum;
  skip: boolean;
};
export type TaskFinalLabelingDialog_unitDefaultRatingQuery$data = {
  readonly unitDefaultRating?: number;
};
export type TaskFinalLabelingDialog_unitDefaultRatingQuery = {
  response: TaskFinalLabelingDialog_unitDefaultRatingQuery$data;
  variables: TaskFinalLabelingDialog_unitDefaultRatingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "schoolGrade"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "schoolYear"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "schoolGrade",
            "variableName": "schoolGrade"
          },
          {
            "kind": "Variable",
            "name": "schoolYear",
            "variableName": "schoolYear"
          }
        ],
        "kind": "ScalarField",
        "name": "unitDefaultRating",
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskFinalLabelingDialog_unitDefaultRatingQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskFinalLabelingDialog_unitDefaultRatingQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a48dac9b16699b7cb972e6ed7dd1b856",
    "id": null,
    "metadata": {},
    "name": "TaskFinalLabelingDialog_unitDefaultRatingQuery",
    "operationKind": "query",
    "text": "query TaskFinalLabelingDialog_unitDefaultRatingQuery(\n  $schoolGrade: UnitSchoolGradeEnum!\n  $schoolYear: UnitSchoolYearEnum!\n  $skip: Boolean!\n) {\n  unitDefaultRating(schoolGrade: $schoolGrade, schoolYear: $schoolYear) @skip(if: $skip)\n}\n"
  }
};
})();

(node as any).hash = "e0a4beb1054cf0d6350884c417e2b537";

export default node;
