/**
 * @generated SignedSource<<8eaa1f7b2eb26ecc08c641345df56538>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EmphasizeTypeEnum = "IMAGE" | "ITEM_LIST" | "NONE" | "TEXT" | "%future added value";
export type MessageTypeEnum = "AD" | "BA" | "EX" | "MI" | "%future added value";
export type TemplateInspectionStatusEnum = "ACCEPT" | "COMPLETE" | "INSPECT" | "REGISTER" | "REJECT" | "%future added value";
export type TemplateStatusEnum = "ACTIVE" | "READY" | "STOP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly createTime: any;
      readonly emphasizeType: EmphasizeTypeEnum;
      readonly id: string;
      readonly messageType: MessageTypeEnum;
      readonly templateCode: string;
      readonly templateInspectionStatus: TemplateInspectionStatusEnum;
      readonly templateName: string;
      readonly templateStatus: TemplateStatusEnum;
    };
  }>;
  readonly " $fragmentType": "AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection";
};
export type AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection$key = {
  readonly " $data"?: AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AlimTalkTemplateEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AlimTalkTemplate",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "templateCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "templateName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "messageType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emphasizeType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "templateStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "templateInspectionStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AlimTalkTemplateConnection",
  "abstractKey": null
};

(node as any).hash = "c1e311fc455d8443410b85c0cb1d9e3c";

export default node;
