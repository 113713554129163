/**
 * @generated SignedSource<<1483e6e12601a84e616b1fa5ec7406b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type TaskProblemAnswerTypeEnum = "objective" | "subjective" | "%future added value";
export type TaskProblemTypeEnum = "copy" | "exam" | "private" | "problem_generator" | "pure" | "%future added value";
export type UnitSchoolGradeEnum = "five" | "four" | "high" | "highest" | "low" | "lowest" | "medium" | "one" | "seven" | "six" | "three" | "two" | "zero" | "%future added value";
export type UnitSchoolYearEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1_2015" | "high_school_1_2022" | "high_school_2_2015" | "high_school_2_daesu_2022" | "high_school_2_mijukbun_2022" | "high_school_3_hwaktong_2022" | "high_school_3_liberal_arts_2015" | "high_school_3_mijukbun2_giha_2022" | "high_school_3_science_2015" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskFinalLabelingDialog_task$data = {
  readonly finalLabeling: {
    readonly difficulty: number;
    readonly id: string;
    readonly unitDs: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null;
  readonly id: string;
  readonly problem: {
    readonly answer: string | null;
    readonly answerChoiceCount: number | null;
    readonly answerType: TaskProblemAnswerTypeEnum | null;
    readonly id: string;
    readonly problem: string | null;
    readonly problemType: TaskProblemTypeEnum;
    readonly solution: string | null;
  } | null;
  readonly scrap: {
    readonly id: string;
    readonly problems: ReadonlyArray<{
      readonly id: string;
      readonly objectUrl: string;
    }>;
    readonly solutions: ReadonlyArray<{
      readonly id: string;
      readonly objectUrl: string;
    }>;
  } | null;
  readonly sequence: number;
  readonly title: string;
  readonly unit: {
    readonly id: string;
    readonly schoolGrades: ReadonlyArray<UnitSchoolGradeEnum>;
    readonly schoolType: SchoolTypeEnum;
    readonly schoolYears: ReadonlyArray<UnitSchoolYearEnum>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PreviousLabelingDialog_task">;
  readonly " $fragmentType": "TaskFinalLabelingDialog_task";
};
export type TaskFinalLabelingDialog_task$key = {
  readonly " $data"?: TaskFinalLabelingDialog_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskFinalLabelingDialog_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskFinalLabelingDialog_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Scrap",
      "kind": "LinkedField",
      "name": "scrap",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "problems",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "solutions",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Unit",
      "kind": "LinkedField",
      "name": "unit",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schoolType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schoolYears",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schoolGrades",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskProblem",
      "kind": "LinkedField",
      "name": "problem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problem",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "solution",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problemType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answerChoiceCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answerType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskLabeling",
      "kind": "LinkedField",
      "name": "finalLabeling",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "difficulty",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "order",
              "value": {
                "order": "DESC"
              }
            }
          ],
          "concreteType": "UnitD",
          "kind": "LinkedField",
          "name": "unitDs",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": "unitDs(order:{\"order\":\"DESC\"})"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreviousLabelingDialog_task"
    }
  ],
  "type": "Task",
  "abstractKey": null
};
})();

(node as any).hash = "f371a90cc765320bf7bf6efd0545428a";

export default node;
