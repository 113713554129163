import { graphql, useFragment } from 'react-relay';

import {
  AlimTalkTaskConnectionDataTable_alimTalkTaskConnection$data,
  AlimTalkTaskConnectionDataTable_alimTalkTaskConnection$key,
} from '../../../relay/__generated__/AlimTalkTaskConnectionDataTable_alimTalkTaskConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import Text from '../../core/Text';

export const AlimTalkTaskConnectionDataTable_alimTalkTaskConnection = graphql`
  fragment AlimTalkTaskConnectionDataTable_alimTalkTaskConnection on AlimTalkTaskConnection {
    edges {
      node {
        id
        sequence
        created
        modified
        createdBy {
          id
        }
        modifiedBy {
          id
        }
        title
        description
        template {
          templateCode
        }
        query
        startAt
        period
        isActive
      }
    }
  }
`;

type AlimTalkTaskNode = AlimTalkTaskConnectionDataTable_alimTalkTaskConnection$data['edges'][number]['node'];

type Props = {
  alimTalkTaskConnection: AlimTalkTaskConnectionDataTable_alimTalkTaskConnection$key;
} & Omit<DataTableProps<AlimTalkTaskNode>, 'rows' | 'columns'>;

const AlimTalkTaskConnectionDataTable = ({ alimTalkTaskConnection, ...props }: Props) => {
  const { edges } = useFragment(AlimTalkTaskConnectionDataTable_alimTalkTaskConnection, alimTalkTaskConnection);

  const rows = edges.map(({ node }) => node).filter((node): node is AlimTalkTaskNode => Boolean(node));

  return (
    <DataTable
      rows={rows}
      columns={[
        {
          field: 'title',
          title: '제목',
          renderValue: ({ title }) => <Text>{title}</Text>,
          width: 160,
        },
        {
          field: 'created',
          title: '생성일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
          width: 120,
        },
        {
          field: 'modified',
          title: '수정일',
          renderValue: ({ modified }) => <DateText>{modified}</DateText>,
          width: 120,
        },
        {
          field: 'template',
          title: '템플릿 코드',
          renderValue: ({ template }) => <Text>{template?.templateCode}</Text>,
          width: 140,
        },
        {
          field: 'startAt',
          title: '시작일시',
          renderValue: ({ startAt }) => <DateText>{startAt}</DateText>,
          width: 140,
        },
        {
          field: 'period',
          title: '주기',
          renderValue: ({ period }) => <Text>{period ? period + '일' : '-'}</Text>,
          width: 50,
        },
        {
          field: 'isActive',
          title: '활성화 여부',
          renderValue: ({ isActive }) => <Text>{isActive ? 'O' : 'X'}</Text>,
          width: 20,
        },
      ]}
      {...props}
    />
  );
};

export default AlimTalkTaskConnectionDataTable;
