import { DotFillIcon, TrashIcon } from '@primer/octicons-react';
import { Suspense, useEffect } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay';

import { BookBookScrapDeleteDialog_book$key } from '../../../relay/__generated__/BookBookScrapDeleteDialog_book.graphql';
import { BookBookScrapDeleteDialog_bookScrapDeleteMutation } from '../../../relay/__generated__/BookBookScrapDeleteDialog_bookScrapDeleteMutation.graphql';
import { BookBookScrapDeleteDialog_bookScrapQuery } from '../../../relay/__generated__/BookBookScrapDeleteDialog_bookScrapQuery.graphql';
import { numberWithCommas } from '../../../utils/number';
import Button from '../../core/Button';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import Label from '../../core/Label';
import MutationConfirmIconButton, { MutationConfirmIconButtonProps } from '../../core/MutationConfirmIconButton';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import Text from '../../core/Text';
import View from '../../core/View';
import BookBookScrapPaginator from '../BookBookScrapPaginator';

const BookBookScrapDeleteDialog_book = graphql`
  fragment BookBookScrapDeleteDialog_book on Book @argumentDefinitions(first: { type: Int }, after: { type: String }) {
    id
    title
    ...BookBookScrapPaginator_book @arguments(first: $first, after: $after)
  }
`;

const bookScrapForBookBookScrapDeleteDialog = graphql`
  query BookBookScrapDeleteDialog_bookScrapQuery($id: ID!) {
    bookScrap(id: $id) {
      id
      difficultyLevel
      unitD {
        id
        title
        unitATitle
        unitBTitle
        unitCTitle
      }
      problemBookPage
      solutionBookPage
      problemNumber
      problem {
        id
        objectUrl
      }
      solution {
        id
        objectUrl
      }
      problemTex
      solutionTex
    }
  }
`;

type Props = {
  book: BookBookScrapDeleteDialog_book$key;
  initialValues: { id: string };
} & DialogProps &
  Pick<MutationConfirmIconButtonProps<BookBookScrapDeleteDialog_bookScrapDeleteMutation>, 'config'>;

const BookBookScrapDeleteDialog = ({ book: bookReference, initialValues, config, isOpen, ...props }: Props) => {
  const book = useFragment(BookBookScrapDeleteDialog_book, bookReference);
  const [queryReference, loadQuery] = useQueryLoader<BookBookScrapDeleteDialog_bookScrapQuery>(
    bookScrapForBookBookScrapDeleteDialog,
  );
  const { title } = book;

  useEffect(() => {
    if (isOpen && initialValues.id) {
      loadQuery({ id: initialValues.id });
    }
  }, [isOpen, initialValues.id]);

  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>
        <Stack gapX={1}>
          <Stack.Item>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>스크랩하기</Text>
          </Stack.Item>
          <Stack.Item>
            <Label variant={'accent'}>{title}</Label>
          </Stack.Item>
        </Stack>
      </Dialog.Header>
      <Grid wrap={false} sx={{ overflowY: 'hidden', height: '100%' }}>
        <Grid.Unit size={3 / 4}>
          {queryReference ? (
            <Suspense
              fallback={
                <View sx={{ padding: 6 }}>
                  <Spinner />
                </View>
              }
            >
              <PreloadedQueryRenderer query={bookScrapForBookBookScrapDeleteDialog} queryReference={queryReference}>
                {({ bookScrap }) => {
                  if (!bookScrap) return null;
                  const {
                    problemBookPage,
                    solutionBookPage,
                    problemNumber,
                    problem,
                    solution,
                    unitD,
                    problemTex,
                    solutionTex,
                    difficultyLevel,
                  } = bookScrap;
                  const unitText = unitD
                    ? [unitD.unitATitle, unitD.unitBTitle, unitD.unitCTitle, unitD.title].join(' > ')
                    : '?';

                  return (
                    <View sx={{ padding: 3, height: '100%', overflowY: 'auto' }}>
                      <Grid gapX={2} sx={{ alignItems: 'center' }}>
                        <Grid.Unit size={'min'}>
                          <Text fontSize={1}>{unitText}</Text>
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          {difficultyLevel ? (
                            <Label size={'small'} variant={'default'}>
                              <Suspense>
                                <EnumPair
                                  typename={'ProblemDifficultyLevelEnum'}
                                  formatter={(value) => `난이도 ${value}`}
                                >
                                  {difficultyLevel}
                                </EnumPair>
                              </Suspense>
                            </Label>
                          ) : null}
                        </Grid.Unit>
                      </Grid>
                      <View sx={{ mt: 3 }}>
                        <Stack gapX={1}>
                          <Stack.Item>
                            <Text
                              fontSize={1}
                              fontWeight={'bold'}
                            >{`${problemBookPage}P의 ${problemNumber} 문제`}</Text>
                          </Stack.Item>
                          <Stack.Item>
                            <Text fontSize={1} fontWeight={'bold'} color={'accent.emphasis'}>
                              {[problem].length}
                            </Text>
                          </Stack.Item>
                        </Stack>
                        <View sx={{ mt: 3 }}>
                          <Grid gapX={4}>
                            <Grid.Unit size={1 / 2}>
                              <Card sx={{ overflowY: 'auto', height: 292 }}>
                                <Image
                                  src={problem.objectUrl}
                                  alt={problem.objectUrl}
                                  html
                                  style={{ display: 'block' }}
                                />
                              </Card>
                            </Grid.Unit>
                            <Grid.Unit size={1 / 2}>
                              <Card sx={{ overflowY: 'auto', height: 292 }}>
                                {problemTex ? (
                                  <Katex>{problemTex}</Katex>
                                ) : (
                                  <View mt={3}>
                                    <EmptyState title={'생성된 tex가 없어요.'} />
                                  </View>
                                )}
                              </Card>
                            </Grid.Unit>
                          </Grid>
                        </View>
                      </View>
                      <View sx={{ mt: 3 }}>
                        <Stack gapX={1}>
                          <Stack.Item>
                            <Text
                              fontSize={1}
                              fontWeight={'bold'}
                            >{`${solutionBookPage}P의 ${problemNumber} 해설`}</Text>
                          </Stack.Item>
                          <Stack.Item>
                            <Text fontSize={1} fontWeight={'bold'} color={'accent.emphasis'}>
                              {[solution].length}
                            </Text>
                          </Stack.Item>
                        </Stack>
                        <View sx={{ mt: 3 }}>
                          <Grid gapX={4}>
                            <Grid.Unit size={1 / 2}>
                              <Card sx={{ overflowY: 'auto', height: 292 }}>
                                <Image
                                  src={solution.objectUrl}
                                  alt={solution.objectUrl}
                                  html
                                  style={{ display: 'block' }}
                                />
                              </Card>
                            </Grid.Unit>
                            <Grid.Unit size={1 / 2}>
                              <Card sx={{ overflowY: 'auto', height: 292 }}>
                                {solutionTex ? (
                                  <Katex>{solutionTex}</Katex>
                                ) : (
                                  <View mt={3}>
                                    <EmptyState title={'생성된 tex가 없어요.'} />
                                  </View>
                                )}
                              </Card>
                            </Grid.Unit>
                          </Grid>
                        </View>
                      </View>
                    </View>
                  );
                }}
              </PreloadedQueryRenderer>
            </Suspense>
          ) : null}
        </Grid.Unit>
        <Grid.Unit
          size={1 / 4}
          sx={{ padding: 5, backgroundColor: 'canvas.inset', height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <BookBookScrapPaginator fragmentReference={book}>
            {({ bookScraps }, { loadMore, hasNext, isLoadingNext }) => (
              <>
                <Stack gapX={1}>
                  <Stack.Item>
                    <Text fontWeight={'bold'} fontSize={1}>
                      스크랩한 문제
                    </Text>
                  </Stack.Item>
                  <Stack.Item>
                    <Text
                      fontWeight={'bold'}
                      fontSize={1}
                      color={bookScraps.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                    >
                      {numberWithCommas(bookScraps.totalCount || 0)}
                    </Text>
                  </Stack.Item>
                </Stack>
                <View
                  sx={{
                    flex: 1,
                    marginTop: 3,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  <ItemList
                    items={bookScraps.edges.map(({ node }) => node).filter((node) => !!node)}
                    renderItem={({ problemBookPage, problemNumber, solutionBookPage }) => (
                      <Text
                        sx={{ fontSize: 1 }}
                      >{`${problemBookPage}P의 ${problemNumber} (해설 ${solutionBookPage}P)`}</Text>
                    )}
                    renderItemWrapper={(children, bookScrap, index) => (
                      <View
                        key={bookScrap.id}
                        onClick={() => loadQuery({ id: bookScrap.id })}
                        sx={{
                          'cursor': 'pointer',
                          'borderRadius': 2,
                          ':hover': { backgroundColor: 'neutral.muted' },
                          ':active': {
                            backgroundColor: 'accent.subtle',
                            fontWeight: 'bold',
                            color: 'accent.emphasis',
                          },
                          ...(queryReference?.variables.id === bookScrap.id
                            ? {
                                backgroundColor: 'accent.subtle',
                                fontWeight: 'bold',
                                color: 'accent.emphasis',
                              }
                            : {}),
                        }}
                        tabIndex={0}
                      >
                        <Grid wrap={false} gapX={1} sx={{ alignItems: 'center', paddingX: 2 }}>
                          <Grid.Unit size={'min'} sx={{ display: 'flex', alignItems: 'center' }}>
                            <StyledOcticon size={12} icon={DotFillIcon} color={'border.default'} />
                          </Grid.Unit>
                          <Grid.Unit size={'max'} sx={{ paddingY: 2 }}>
                            {children}
                          </Grid.Unit>
                          <Grid.Unit size={'min'}>
                            {queryReference?.variables.id === bookScrap.id &&
                            bookScrap.actions.includes('book_scrap_delete') ? (
                              <MutationConfirmIconButton<BookBookScrapDeleteDialog_bookScrapDeleteMutation>
                                tabIndex={-1}
                                mutation={graphql`
                                  mutation BookBookScrapDeleteDialog_bookScrapDeleteMutation(
                                    $input: BookScrapDeleteInput!
                                  ) {
                                    bookScrapDelete(input: $input) {
                                      id @deleteRecord
                                    }
                                  }
                                `}
                                input={{ id: bookScrap.id }}
                                message={'삭제한 문제는 되돌릴 수 없어요. 삭제할까요?'}
                                variant={'invisible'}
                                size={'small'}
                                config={{
                                  ...config,
                                  onCompleted: (...args) => {
                                    // ㅠㅠ
                                    if (bookScraps.edges[index + 1]?.node?.id)
                                      loadQuery({ id: bookScraps.edges[index + 1].node.id });
                                    else props.onDismiss?.();
                                    config?.onCompleted?.(...args);
                                  },
                                }}
                                icon={TrashIcon}
                                aria-label={'delete scrap'}
                              />
                            ) : null}
                          </Grid.Unit>
                        </Grid>
                      </View>
                    )}
                  />
                  {hasNext ? (
                    <Button onClick={() => loadMore(100)} disabled={isLoadingNext} sx={{ width: '100%', marginTop: 1 }}>
                      더보기
                    </Button>
                  ) : null}
                </View>
              </>
            )}
          </BookBookScrapPaginator>
        </Grid.Unit>
      </Grid>
    </Dialog>
  );
};

export default BookBookScrapDeleteDialog;
